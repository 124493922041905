import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './table-of-contents.module.css'
import Layout from '../../wizardsusevimbook/Layout'
import SEO from '../../components/seo'
import AgnosticLink from '../../components/AgnosticLink'
import { Arcana } from '../../wizardsusevimbook/components/arcana/arcana'

export default class TableOfContents extends React.Component {
  render() {
    const { data } = this.props
    // BUG: According to the page query below, these should already be appropriately sorted.
    // And that's how it works when running `gatsby develop`, but when you use a production
    // build with `gatsby build` it doesn't work. All chapters appear unsorted. Until I can
    // figure out how to fix it properly I'll sort the chapters in this component as well.
    // The sort below should not be necessary.
    const chapters = data.allMdx.edges
      .map((e) => e.node)
      .sort((a, b) => a.frontmatter.chapter > b.frontmatter.chapter)

    // Group chapters by volume
    const chaptersPerVolume = chapters.reduce((chaptersPerVolume, chapter) => {
      const volume = chapter.frontmatter.volume
      const volumeDescription = chapter.frontmatter.volume_description
      if (chaptersPerVolume.has(volume)) {
        chaptersPerVolume.get(volume).chapters.push(chapter)
      } else {
        chaptersPerVolume.set(volume, { description: volumeDescription, chapters: [chapter] })
      }
      return chaptersPerVolume
    }, new Map())

    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout>
        <article className={styles.page}>
          <SEO
            title={`Wizards Use Vim - Table Of Contents | ${siteTitle}`}
            keywords={[`vim`, `neovim`, `programming`]}
            faviconUrl="/wizards-use-vim/vim-icon.png"
          />
          <section className={styles.content}>
            <h1>Table Of Contents</h1>
            {[...chaptersPerVolume.entries()].map(([volume, { description, chapters }]) =>
            (
              <Chapters volume={volume} volumeDescription={description} chapters={chapters} />
            )
            )}
            <p className={styles.comingSoon}>More chapters coming soon...</p>
          </section>
        </article>
        <Arcana />
      </Layout>
    )
  }
}

const Chapters = ({ volume, volumeDescription, chapters }) => (
  <>
    <h2 className={styles.volume}>Volume {volume + 1}. {volumeDescription} </h2>
    <section className={styles.chapters}>
      {chapters.map((c) => (
        <section className={styles.chapter} key={c.frontmatter.chapter}>
          <h3>
            <AgnosticLink href={c.fields.slug}>
              {c.frontmatter.chapter}. {c.frontmatter.title}
            </AgnosticLink>
          </h3>
          <p className={styles.description}>{c.frontmatter.description}</p>
        </section>
      ))}
    </section>
  </>
)

export const pageQuery = graphql`
  query WizardsUseVimTOCQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___chapter], order: ASC }
      filter: {
        frontmatter: {
          draft: { eq: false }
          book: { eq: "Wizards Use Vim" }
        }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            chapter
            description
            volume
            volume_description
            exercise
            type
          }
          timeToRead
        }
      }
    }
  }
`
